<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <v-form
        @submit.prevent="handleSubmit(updateAffiliateCode)"
        method="post"
        id="check-form"
      >
        <v-container>
          <v-row class="pt-10 mt-10">
            <!-- number of times usable by the user -->
            <v-col class="mx-auto pa-0" cols="10">
              <div class="text-h6 ml-1">
                {{ lang.title.payment }}<span class="red--text mr-4">(*)</span>
              </div>
              <div class="ml-4 mt-5">
                <v-row align="center">
                  <v-select
                    :items="paymentMethod"
                    class="shrink money-box"
                    dense
                    item-text="label"
                    v-model="affiliateCode.type"
                    outlined
                    color="primary"
                  ></v-select>
                  <vuetify-money
                      v-model="affiliateCode.amount_money"
                      v-bind:label="''"
                      v-bind:placeholder="'Nhập số tiền khuyến mại'"
                      v-bind:readonly="false"
                      v-bind:disabled="false"
                      v-bind:outlined="true"
                      v-bind:clearable="true"
                      v-bind:valueWhenIsEmpty="''"
                      v-bind:options="options"
                      class="money-box-input"
                  />
                  <div class="icon-hnr pa-2 money__icon-fix">
                    <v-icon>
                      mdi-cash
                    </v-icon>
                  </div>
                </v-row>
                <span class="validate-error text-danger text-danger">
                  {{ paymentNumberErrorMessage }}
                </span>
              </div>
            </v-col>
            <!-- describe the discount code information -->
            <v-col class="mx-auto pa-0" cols="10">
              <div class="text-h6 ml-1">
                {{ lang.title.describe }}
              </div>
              <div class="ml-4 mt-5">
                <v-row align="center">
                  <hnr-textarea
                      :text="affiliateCode.description"
                      v-model="affiliateCode.description"
                      :label="lang.label.describe"
                      class="input-textArea"
                  ></hnr-textarea>
                </v-row>
              </div>
            </v-col>
            <v-col cols="12">
              <span class="ml-14 pl-13 text-left red--text"
                >(*) : Bắt buộc nhập</span
              >
            </v-col>
            {{affiliateCode.type}}
            <v-col class="mx-auto text-right" cols="8">
              <hnr-button
                type="submit"
                form="check-form"
                rounded
                width="200"
                text="Cập nhật mã giới thiệu"
                color="blue"
                white-text="true"
              ></hnr-button>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </ValidationObserver>
    <!-- start time picking box -->
  </div>
</template>

<script>
import HnrButton from "@/components/elements/hnr-button";
import HnrTextarea from "@/components/elements/hnr-textarea";
import {GET_INFO_AFILIATE_CODE, GET_UPDATE_AFFILIATE_CODE} from "@/store/affiliateCode.module";
import { mapGetters } from "vuex";

export default {
  components: {
    HnrTextarea,
    HnrButton
  },

  data: () => ({
    errorMessagesPayment: "",
    amountInputError: "",
    lang: {
      title: {
        course: "Chọn khóa học",
        amountUsed: "Số lần sử dụng",
        code: "Mã giới thiệu",
        describe: "Mô tả",
        money: "Phần trăm(%)",
        all: "Tất cả",
        payment: "Cách thức giảm giá"
      },
      label: {
        course: "Chọn khóa học",
        amountUsed: "0",
        code: "Nhập mã khuyến mại",
        describe: "Nhập mô tả"
      }
    },
    paymentMethod: [
      {
        value: 1,
        label: "Tiền mặt(đ)"
      },
      {
        value: 2,
        label: "Phần trăm(%)"
      }
    ],
    description:  null,
    paymentNumberErrorMessage: "",
    price: "",
    usedNumberErrorMessage: "",
    inputMoney:{
      label: "",
      placeholder: "Nhập mệnh giá theo phần trăm hoặc theo VNĐ",
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: true,
      valueWhenIsEmpty: "",
    },
    options: {
      locale: "pt-BR",
      prefix: "VNĐ$",
      suffix: "",
      length: 100,
      precision: 0,
    },
  }),
  async created() {
    await this.$store.dispatch(GET_INFO_AFILIATE_CODE, {
      code_id: this.$route.query.code_id,
    });
  },
  watch: {
    "affiliateCode.description": function (val) {
      this.description = val;
    },
    "affiliateCode.amount_money": function(val) {
      this.price = "";
      this.price = this.formatNumber(val);
      if (!val) {
        this.paymentNumberErrorMessage = "Ô này không được bỏ trống";
      } else if (isNaN(val)) {
        this.paymentNumberErrorMessage = "Ô này không được là chữ cái";
      } else if (val < 0) {
        this.paymentNumberErrorMessage = "Giá trị nhập phải lớn hơn 0";
      } else if (this.affiliateCode.type === 0) {
        if (val > 100) {
          this.paymentNumberErrorMessage = "Không được vượt quá 100%";
        } else {
          this.paymentNumberErrorMessage = "";
        }
      } else {
        this.paymentNumberErrorMessage = "";
      }
    },
  },
  computed: {
    ...mapGetters({
      affiliateCode: "getCodeById"
    })
  },
  methods: {
    async updateAffiliateCode() {
      if (!this.affiliateCode.amount_money) {
        this.paymentNumberErrorMessage = "Ô này không được bỏ trống";
      } else if (this.affiliateCode.amount_money < 0) {
        this.paymentNumberErrorMessage = "Giá trị nhập phải lớn hơn 0";
      } else if (this.affiliateCode.type == 2) {
        if (this.affiliateCode.amount_money > 100) {
          this.paymentNumberErrorMessage = "không được vượt quá 100%";
        } else {
          if (this.affiliateCode.type == 1) {
            this.affiliateCode.type = 1;
          } else {
            this.affiliateCode.type = 2;
          }
          this.affiliateCode.description = this.description;
          await this.$store.dispatch(GET_UPDATE_AFFILIATE_CODE, this.affiliateCode);
          this.$router.push({
            path: "/afflificate/detail/" + this.$route.query.code_id
          });
        }
      } else {
        this.affiliateCode.description = this.description;
        await this.$store.dispatch(GET_UPDATE_AFFILIATE_CODE, this.affiliateCode);
        this.$router.push({
          path: "/afflificate/detail/"+ this.$route.query.code_id
        });
      }
    },
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
  }
};
</script>

<style lang="scss">
.autoCompleteBox {
  .v-input__control {
    .v-input__slot {
      width: 102%;
    }
  }
}

.checkbox-course {
  padding: 9px !important;
  position: relative;
  left: -102px;
  border: 1px solid #757575;
  background: #e0e0e0;
  border-bottom: 1px solid lightgray;

  .v-input__control {
    .v-input__slot {
      label {
        margin-bottom: 0;
      }
    }
  }
}

.checkbox-amount-used {
  height: 45px;
  padding: 9px !important;
  position: relative;
  left: -10%;
  border: 1px solid #757575;
  background: #e0e0e0;
  /* border-bottom: 1px solid lightgray; */
  margin-top: 18px;

  .v-input__control {
    .v-input__slot {
      label {
        margin-bottom: 0;
      }
    }
  }
}

.money-box {
  position: relative;
}

.input-money {
  .v-input__control {
    .v-input__slot {
      width: 86.5%;
    }
  }
}

.input-amount-used {
  .v-input__control {
    .v-input__slot {
      width: 107%;
    }
  }
}

.input-textArea {
  .v-input__control {
    .v-input__slot {
      width: 87.5%;
    }
  }
}

.input-hnr {
  .v-input__control {
    .v-input__slot {
      width: 91.5%;
    }
  }
}

.autoCompleteBox {
  .v-input__control {
    v-input__slot {
      width: 70%;
    }
  }
}
.money-box-input{
  width: 53%;
  .v-input__control{
    height: 66px;
    .v-input__slot{
      fieldset{
        height: 45px;
      }
    }
    .v-text-field__prefix{
      display: none;
    }
    input{
      padding-bottom: 26px;
    }
  }
}
.money__icon-fix{
  left: -43px !important;
}
.icon-hnr {
  height: 43px;
  position: relative;
  left: -12.5%;
  bottom: 17px;
  border: 1px solid #757575;
  background: #e0e0e0;
  /* border-bottom: 2px solid lightgray; */
  margin-bottom: -3px;
}
</style>
